import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  resources: {
    rs: {
      translation: {
        title:
          "Gold Digital Agencija - Izrada Web Sajta, optimizacija sajta za sve uredjaje i izrada visejezicnog sajta - Digital Web",
        //Navbar
        home: "Početna",
        gohome: "Vrati se na početnu stranicu",
        error404:
          "Stranica koju tražite je možda uklonjena, ime promenjeno ili je privremeno nedostupna.",
        services: "Usluge",
        seo: "SEO Optimizacija",
        portfolioNav: "Portfolio",
        portfolioTitle: "Portfolio",
        about: {
          navLink: "O nama",
          title: "Gradimo snažne brendove kroz jedinstvena digitalna iskustva",
          text1:
            "Bez obzira na vaše ciljeve, naša digitalna agencija je vaš ključ do ostvarivanja pravih rezultata u digitalnom okruženju.",
          our_clients: "Naši klijenti",
          kosmomi_title: "Ko smo mi?",
          kosmomi_text:
            "Gold Digotal je dinamična marketinška agencija čiji je osnovni cilj da unapredi vašu online prisutnost i postigne izuzetne rezultate. Naš tim čine mladi strastveni stručnjaci sa širokim spektrom veština, od web dizajna i development-a do digitalnog marketinga i analitike. Ujedinjeni u zajedničkom cilju da vaš brend zablista, posvećeni smo pružanju vrhunskih usluga prilagođenih vašim potrebama.",
          staradimo_title: "Šta radimo?",
          staradimo_text:
            "Kroz naš širok spektar usluga, pružamo sveobuhvatna digitalna marketinška rešenja koja će podići vaš brend na digitalni vrh. Razvijamo kreativne kampanje, optimizujemo web stranice, upravljamo društvenim mrežama i analiziramo podatke kako bismo osigurali da svaki korak bude ciljan i efikasan. Bez obzira na to da li ste novi brend u potrazi za prepoznatljivim identitetom ili već dobro uspostavljen brend koji želi osveženje, mi smo tu da vam pomognemo.",
          izaberinas: {
            title: "Zašto izabrati nas?",
            subtitle1: "Stručnost",
            subtitle2: "Prilagodljivost",
            subtitle3: "Kreativnost",
            subtitle4: "Transparentnost",
            text1:
              "Naš tim čine mladi profesionalci sa bogatim iskustvom u digitalnom marketingu. Pratimo najnovije trendove i tehnologije kako bismo vam pružili najbolje moguće usluge",
            text2:
              "Razumemo da svaki klijent ima jedinstvene potrebe. Stoga prilagođavamo naša rešenja kako bismo odgovarali vašem brendu i ciljevima.",
            text3:
              "Verujemo da snaga kreativnosti izdvoja brend iz mase. Naši dizajneri i marketinški stručnjaci rade zajedno kako bi stvorili sve što je potrebno da napravimo razliku u odnosu na vašu konkurenciju.",
            text4:
              "Kod nas nema skrivenih troškova ni neprijatnih iznenađenja. Svesni smo važnosti transparentnosti u poslovanju i gradimo odnos poverenja sa svakim klijentom."
          }
        },
        feedback: {
          title: "Šta naši klijenti kažu o nama",
          text1:
            "Kao neko kome je vizuelni identitet jako bitan, sva moja očekivanja su prevaziđena. Kako je sajt zamišljen, još bolje je realizovan. Profesionalnost i kreativnost na zavidnom nivou.",
          text2:
            "Saradnja sa Gold Digitalom značajno je unapredila moje poslovanje i donela mi brojne benefite. Njihova profesionalnost je na izuzetno visokom nivou, što se ogleda u svakom aspektu saradnje – od početne komunikacije, preko planiranja, pa sve do realizacije projekata. Tim je neverovatno pristupačan i otvoren za sugestije, čineći rad s njima jednostavnim i prijatnim iskustvom. Pored toga, ističu se kreativnošću i inovativnim rešenjima, što je značajno doprinelo razvoju mog biznisa. Njihova posvećenost detaljima i želja da klijent bude potpuno zadovoljan čini ih pravim partnerom za svakoga ko želi da podigne svoje poslovanje na viši nivo. Topla preporuka svima koji traže profesionalnu i pouzdanu digitalnu agenciju. Sve pohvale za Gold Digital!",
          text3:
            "Želim da izrazim svoje ogromno zadovoljstvo radom Gold Digitala i uslugama koje pružaju za ažuriranje i izradu sajtova. Njihov profesionalizam, posvećenost i pažnja prema detaljima su zaista impresivni. Od samog početka saradnje, pokazali su veliko razumevanje mojih potreba i zahteva, te je svaka faza izrade sajta protekla glatko i bez problema. <br/> Tim Gold Digitala je uvek dostupan za konsultacije i brzo reaguje na sve sugestije i izmene, što mi je uveliko olakšalo proces. Zahvaljujući njima, moj sajt sada izgleda moderno, funkcionalno i privlačno, a ažuriranja se obavljaju brzo i efikasno. Ako tražite nekoga ko će se istinski posvetiti vašem projektu, pružiti visok kvalitet usluge i profesionalno obaviti posao, Gold Digital je prava agencija za to. Dajem sve preporuke!",
          text4: `Saradnja sa Gold Digitalom bila je izuzetno profesionalna i efikasna! Naš novi sajt za izradu uniformi prevazišao je sva očekivanja. Dizajn je moderan, funkcionalan i prilagođen potrebama naših klijenata, a navigacija je veoma intuitivna. Posebno cenimo što su sve naše ideje i zahtevi bili uzeti u obzir, a tim je bio dostupan za svaku konsultaciju. Zahvaljujući ovom sajtu, naši klijenti sada imaju bolje iskustvo, a naš posao značajno je unapređen. Toplo preporučujemo svakome ko traži vrhunskog profesionalca za izradu sajtova!`,
          text5: `Gold Digital tim je zaista fenomenalan i izuzetno profesionalan. Saradnja s njima bila je brza, efikasna i laka, jer pažljivo slušaju instrukcije i uvek dele svoja razmišljanja na vreme. Od početka je bilo očigledno da imaju veliko iskustvo i da znaju šta rade, jer su svaku fazu projekta obavili sa preciznošću. Website su odradili fantastično, kao i sve vizuale koje sam tražio. Bio sam potpuno zadovoljan rezultatom i sigurno bih ih preporučio svakome ko traži kvalitetnu uslugu. Njihova posvećenost i stručnost čine ih odličnim izborom za bilo koji projekat.`
        },
        links: {
          site: "Web Development",
          shop: "E-Commerce",
          app: "Mobile Development",
          networks: "Social Media Marketing",
          seo: "SEO",
          ppc: "PPC",
          design: "Web Dizajn",
          redesign: "Redizajn Sajta",
          logo: "Logotip Dizajn"
        },
        contact: "Kontakt",
        send: "Pošalji",
        textLng: "EN",
        // lngFun: "en",
        social: "Društvene mreže",
        newsletter: "Mail lista",
        homeText: "Full-Service digitalna agencija koja pokreće biznise",
        homeSubTitle:
          "Od razvoja web, e-commerce i mobilnih aplikacija do digitalnog marketinga i brendiranja – kreiramo rešenja koja donose rezultate.",
        view: "Pogledajte sajt",
        btnGet: "Upit za saradnju",
        startProject: "Započnite novi projekat?",
        infoText:
          "Imate pitanja ili želite da saznate više o tome kako možemo pomoći vašem poslovanju? Kontaktirajte nas.",
        fullName: "Ime i Prezime",
        more: "Više",
        message: "Poruka",
        sendBtn: "Pošalji",
        phoneText: "Pozovite na broj",
        emailText: "Pošaljite email",
        copyright: "Sva prava su zadržana. ",
        errorMsg: "Ovo polje je obavezno.",
        errorEmail: "Email adresa nije validna.",
        changeLngMsg: "Promenjen jezik",
        seoSub: "SEO optimizacija ključni koraci za uspjeh vaše web stranice",
        seoText1:
          "U digitalnom dobu, optimizacija za pretraživače (SEO) je ključni faktor za uspeh vaše veb stranice. Ova tehnika omogućava bolju vidljivost na pretraživačima kao što su Google, Bing i Yahoo, što dovodi do većeg organskog saobraćaja i potencijalnih korisnika. Evo nekoliko ključnih faktora koje treba uzeti u obzir pri optimizaciji vaše veb stranice:",
        cookiesText:
          "Dobrodošli na naš sajt! Kako bismo vam pružili najbolje moguće iskustvo, koristimo kolačiće koji prikupljaju anonimne podatke o vašem pregledanju. Nastavljajući korišćenje sajta, slažete se s našom Politikom kolačića koja detaljno opisuje njihovo korišćenje i vaša prava u vezi sa privatnošću.",
        cookiesBtn: "Prihvatiti",
        siteMap: "Linkovi",
        thenksText:
          "Uspešno ste poslali poruku, očekujte odgovor u roku od 24h."
      }
    },
    en: {
      translation: {
        title:
          "Golg Digital Agency - creation of professional web sites, optimization for all devices and creation of a multilingual site.",
        //Navbar
        home: "Home",
        gohome: "Go back to homepage",
        error404:
          "The page you are looking for may have been removed, renamed or temporarily unavailable.",
        portfolioNav: "Portfolio",
        portfolioTitle: "Portfolio",
        seo: "SEO optimization",
        services: "Services",
        send: "Send",
        about: {
          navLink: "About us",
          title: "<h1>Meet GOLD DIGITAL agency</h1>",
          text1:
            "Regardless of your goals, our digital agency is your key to achieving real results in the digital environment.",
          our_clients: "Our clients",
          subtitleHome: "Why our agency meets your needs",
          more: "More",
          kosmomi_title: "Who are we?",
          kosmomi_text:
            "Gold Digital is a dynamic marketing agency whose primary goal is to enhance your online presence and achieve exceptional results. Our team consists of young, passionate experts with a broad range of skills, from web design and development to digital marketing and analytics. United in the common goal of making your brand shine, we are committed to providing top-notch services tailored to your needs.",
          staradimo_title: "What do we do?",
          staradimo_text:
            "Through our wide range of services, we provide comprehensive digital marketing solutions that will elevate your brand to the digital forefront. We develop creative campaigns, optimize websites, manage social media, and analyze data to ensure that every step is targeted and efficient. Whether you're a new brand in search of a distinctive identity or an already well-established brand seeking a refresh, we are here to help.",
          izaberinas: {
            title: "Why choose us?",
            subtitle1: "Expertise",
            subtitle2: "Adaptability",
            subtitle3: "Creativity",
            subtitle4: "Transparency",
            text1:
              "Our team consists of young professionals with rich experience in digital marketing. We stay updated on the latest trends and technologies to provide you with the best possible services.",
            text2:
              "We understand that each client has unique needs. Therefore, we tailor our solutions to fit your brand and goals.",
            text3:
              "We believe that the power of creativity sets a brand apart. Our designers and marketing experts work together to create everything needed to make a difference compared to your competition.",
            text4:
              "With us, there are no hidden costs or unpleasant surprises. We are aware of the importance of transparency in business and build a relationship of trust with every client."
          }
        },
        links: {
          site: "Website",
          shop: "eCommerce",
          app: "Mobile App",
          networks: "Social Media Marketing",
          seo: "SEO",
          ppc: "PPC",
          design: "Web Design",
          redesign: "Website Redesign",
          logo: "Logo Design"
        },
        feedback: {
          title: "Client experiences",
          text1:
            "As someone who values visual identity greatly, all my expectations have been surpassed. The website was conceived in a certain way, but it was even better realized. The level of professionalism and creativity is outstanding.",
          text2:
            "My collaboration with Gold Digital has brought nothing but a significant boost to my business. The level of professionalism is outstanding. They are approachable, easy to work with, and highly creative. I have nothing but praise and wholeheartedly recommend them to anyone looking to take their business to the next level."
        },
        contact: "Contact",
        textLng: "SR",
        // lngFun: "rs",
        homeText: "Digital Marketing Agency",
        homeSubTitle:
          "Push the boundaries of your online success. Your partner for innovative marketing solutions.",
        view: "View site",
        btnGet: "Request for cooperation",
        startProject: "Start a new project?",
        infoText:
          "For all business inquiries and collaboration, please contact us using the provided contact information below. Our team is ready to respond to your requests and provide you with professional support.",
        fullName: "Full Name",
        message: "Message",
        more: "More",
        sendBtn: "Send",
        phoneText: "Call the number",
        emailText: "Send an email",
        social: "Social network",
        newsletter: "Newsletter",
        copyright: "All rights reserved. ",
        errorMsg: "This field is required.",
        errorEmail: "The email address is not valid.",
        changeLngMsg: "Changed language",
        seoSub: "SEO optimization key steps for the success of your website",
        seoText:
          "Do you want to improve the visibility of your website on search engines and achieve top-notch results?",
        seoText1:
          "In the digital age, search engine optimization (SEO) is a crucial factor for the success of your website. This technique allows for better visibility on search engines such as Google, Bing, and Yahoo, leading to increased organic traffic and potential users. Here are a few key factors to consider when optimizing your website:",
        accessibility: "Site accessibility",
        performance: "Performance",
        structure: "Correct site structure",
        code: "Correctly written code",
        multiSubTitle: "Global connectivity",
        cookiesText:
          "Welcome to our site! In order to provide you with the best possible experience, we use cookies that collect anonymous data about your browsing. By continuing to use the site, you agree to our Cookie Policy which details their use and your privacy rights.",
        cookiesBtn: "Accept",
        siteMap: "Links",
        thenksText:
          "You have successfully sent a message, expect a response within 24 hours."
      }
    }
  },
  lng: localStorage.getItem("lng") || "rs"
});

export default i18next;
