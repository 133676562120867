import React from "react";
import { Container } from "../../style";
import "../../components/Testimonial/testimonial.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import maja from "../../assets/Img/clients/maja.png";
import miha from "../../assets/Img/clients/miha.png";
import filip from "../../assets/Img/clients/filip.png";
import marko from "../../assets/Img/clients/marko.avif";
import unijana from "../../assets/Img/clients/unijana.jpg";
import { FaQuoteLeft } from "react-icons/fa";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function Testimonial() {
  const { t } = useTranslation();
  return (
    <div>
      <Container>
        <h2 style={{ marginTop: 0 }} className="sub-title">
          {t("feedback.title")}
        </h2>
        <Carousel
          infinite={true}
          autoPlaySpeed={5000}
          autoPlay={true}
          responsive={responsive}
          showDots
        >
          <div className="testimonial-slider">
            <FaQuoteLeft
              style={{ fontSize: 50, color: "var(--primary-color)" }}
            />
            <p dangerouslySetInnerHTML={{ __html: t("feedback.text4") }} />

            <div className="title-testi">
              <div className="img-testi">
                <img src={unijana} alt="Unijana" />
              </div>
              <div>
                <h4>Sladjana Savković</h4>
                <span style={{ color: "#afafaf" }}>CEO of Unijana</span>
              </div>
            </div>
          </div>

          <div className="testimonial-slider">
            <FaQuoteLeft
              style={{ fontSize: 50, color: "var(--primary-color)" }}
            />
            <p dangerouslySetInnerHTML={{ __html: t("feedback.text5") }} />
            <div className="title-testi">
              <div className="img-testi">
                <img src={marko} alt="Marko" />
              </div>
              <div>
                <h4>Marko Radović</h4>
                <span style={{ color: "#afafaf" }}>IFBB Online trener</span>
              </div>
            </div>
          </div>

          <div className="testimonial-slider">
            <FaQuoteLeft
              style={{ fontSize: 50, color: "var(--primary-color)" }}
            />
            <p dangerouslySetInnerHTML={{ __html: t("feedback.text3") }} />
            <div className="title-testi">
              <div className="img-testi">
                <img src={filip} alt="Filip" />
              </div>
              <div>
                <h4>Filip Mitrović</h4>
                <span style={{ color: "#afafaf" }}>Filip Mitrović Bend</span>
              </div>
            </div>
          </div>

          <div className="testimonial-slider">
            <FaQuoteLeft
              style={{ fontSize: 50, color: "var(--primary-color)" }}
            />
            <p>
              {t("feedback.text2")}
            </p>
            <div className="title-testi">
              <div className="img-testi">
                <img src={miha} alt="Mihailo" />
              </div>
              <div>
                <h4>Mihailo Zivanović</h4>
                <span style={{ color: "#afafaf" }}>Personalni trener</span>
              </div>
            </div>
          </div>

          <div className="testimonial-slider">
            <FaQuoteLeft
              style={{ fontSize: 50, color: "var(--primary-color)" }}
            />
            <p>
              {t("feedback.text1")}
            </p>

            <div className="title-testi">
              <div className="img-testi">
                <img src={maja} alt="Maja" />
              </div>
              <div>
                <h4>Maja Lazović</h4>
                <span style={{ color: "#afafaf" }}>
                  CEO of Plesni studio Markoreografija
                </span>
              </div>
            </div>
          </div>
        </Carousel>
      </Container>
    </div>
  );
}
